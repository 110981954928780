<template>
  <el-dialog
    :title="$t('yh.zichan')"
    :visible.sync="visible"
    center
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="closeassetsdialog"
    @open="assets"
    :before-close="handleClose"
  >
    <div class="has" v-if="total !== 0">
      <!--<el-row>
        <el-col :span="8">
          <b>{{ $t('yh.zongji') }}：</b>{{ hasassets.total }}
        </el-col>
        <el-col :span="8">
          <b>{{ $t('yh.bibi') }}：</b>{{ hasassets.usable }}
        </el-col>
        <el-col :span="8">
          <b>{{ $t('yh.bbdj') }}：</b>{{ hasassets.freeze }}
        </el-col>
      </el-row>
       
       <el-row>
        <el-col :span="8">
          <b>{{ $t('yh.fabi') }}：</b>{{ hasassets.legal_tender }}
        </el-col>
        <el-col :span="8">
          <b>{{ $t('yh.fbdj') }}：</b>{{ hasassets.legal_tender_freeze }}
        </el-col>
        <el-col :span="8">
          <b>{{ $t('yh.cplx') }}：</b>{{ hasassets.product_type }}
        </el-col>
      </el-row> -->

      <el-row>
        <el-col :span="8">
          <b>USDT余额：</b>{{ hasassets.contract }}
        </el-col>
        <el-col :span="8">
          <b>USDT冻结：</b>{{ hasassets.contract_freeze }}
        </el-col>

        <!-- <el-col :span="8"> <b>DeFi：</b>{{ hasassets.defi }} </el-col> -->
      </el-row>
      <!-- <el-row>
        <el-col :span="8">
          <b>{{ $t('yh.dfdj') }}：</b>{{ hasassets.defi_freeze }}
        </el-col>
        <el-col :span="8"></el-col>
        <el-col :span="8"></el-col>
      </el-row> -->
    </div>
    <div v-else>{{ $t('yh.gzhxwzc') }}</div>
    <el-divider></el-divider>
    <el-form
      ref="assetsform"
      :model="assetsform"
      :rules="formrules"
      label-width="120px"
    >
      <el-form-item :label="$t('yh.czsl')" prop="num">
        <el-input-number
          v-model="assetsform.num"
          :precision="3"
          :step="0.001"
          mav="9999999999999999999999999999"
        ></el-input-number>
      </el-form-item>
      <el-form-item :label="$t('yh.xzjj')" prop="type">
        <el-radio-group v-model="assetsform.type">
          <el-radio label="1">加</el-radio>
          <el-radio label="0" :disabled="total === 0">减</el-radio>
        </el-radio-group>
      </el-form-item>
      <!--
      <el-form-item :label="$t('yh.bibi') + '/' + $t('yh.fabi')" prop="account">
        <el-select v-model="assetsform.account" :placeholder="$t('qxz')">
          <el-option :label="$t('yh.bibi')" value="usable"></el-option>
          <el-option :label="$t('yh.fabi')" value="legal_tender"></el-option>
          <el-option :label="外汇" value="contract">外汇</el-option>
          <el-option label="defi" value="defi"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item :label="$t('yh.cplx')" prop="productid">
        <el-select v-model="assetsform.productid" :placeholder="$t('qxz')">
          <el-option
            v-for="item in product"
            :key="item.id"
            :label="item.product_name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      -->
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="assetssubmit">{{
        $t('tijiao')
      }}</el-button>
      <el-button @click="handleClose">{{ $t('quxiao') }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      total: 0,
      product: [],
      // 拥有的资产对象
      hasassets: {
        contract: 0,
        legal_tender: 0,
        defi: 0
      },
      // 加减资产数据对象
      assetsform: {
        uid: '',
        num: '0.001',
        type: '1',
        account: 'contract',
        productid: '1'
      },
      // 表单验证对象
      formrules: {
        num: [
          { required: true, message: '必填项0.001-9999999999999999999999999999', trigger: 'blur' }
        ],
        type: [{ required: true, message: '请选择操作类型', trigger: 'blur' }],
        account: [{ required: true, message: '请选择币种', trigger: 'blur' }],
        productid: [
          { required: true, message: '请选择产品类型', trigger: 'blur' }
        ]
      }
    }
  },
  props: ['visible', 'uid'],
  methods: {
    handleClose() {
      this.$emit('close')
    },
    // 打开资产弹窗
    async assets() {
      const loading = this.$loading({
        lock: true,
        spinner: 'el-icon-loading',
        background: 'rgba(255,255, 255, 0.7)',
        target: document.querySelector('.el-dialog__body')
      })
      // 请求用户资产数据
      const { data } = await this.$http.get(
        '/admin/user/assets/uid/' + this.uid
      )
      if (data) {
        if (data.code === 200) {
          this.total = +data.data.info.total + 0
          this.hasassets = data.data.info
          this.assetsform.uid = data.data.info.user_id
          this.product = data.data.product
        } else {
          this.$message.error(this.$t('shibai'))
        }
      }
      loading.close()
    },
    // 关闭asset弹窗
    closeassetsdialog() {
      this.$refs.assetsform.resetFields()
      this.assetsdialogvisible = false
    },
    assetssubmit() {
      this.$refs.assetsform.validate(async valid => {
        if (!valid) return false
        const { data } = await this.$http.post(
          '/admin/user/assets',
          this.assetsform
        )
        if (data) {
          if (data.code === 200) {
            this.total = +this.total + this.assetsform.num + 0
            this.$message.success(this.$t('chenggong'))
          } else {
            this.$message.error(this.getlang(data.msg))
          }
        }
        this.handleClose()
      })
    }
  }
}
</script>
<style lang="less" scoped>
.el-row {
  line-height: 30px;
}
</style>
