<template>
  <el-dialog
    :title="$t('yh.djzc')"
    :visible.sync="visible"
    center
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="closeassetsdialog"
    @open="open"
    :before-close="handleClose"
  >
    <div class="has" v-if="total !== 0">
        <!-- <el-row>
        <el-col :span="8">
          <b>{{ $t('yh.bibi') }}：</b>{{ hasassets.usable }}
        </el-col>
        <el-col :span="8">
          <b>{{ $t('yh.bbdj') }}：</b>{{ hasassets.freeze }}
        </el-col>
        <el-col :span="8">
          <b>{{ $t('yh.fabi') }}：</b>{{ hasassets.legal_tender }}
      </el-col>
      </el-row>-->
      <el-row>
         <!-- <el-col :span="8">
          <b>{{ $t('yh.fbdj') }}：</b>{{ hasassets.legal_tender_freeze }}
        </el-col> -->
        <el-col :span="8">
           <b>USDT余额：</b>{{ hasassets.contract }}
        </el-col>
        <el-col :span="8">
          <b>USDT冻结：</b>{{ hasassets.contract_freeze }}
        </el-col>
      </el-row> 
      <!-- <el-row>
        <el-col :span="8"> <b>DeFi：</b>{{ hasassets.defi }} </el-col>
         <el-col :span="8">
          <b>{{ $t('yh.dfdj') }}：</b>{{ hasassets.defi_freeze }}
        </el-col>
        </el-row> -->
    </div>
    <div v-else>{{ $t('yh.gzhxwzc') }}</div>
    <el-divider></el-divider>
    <el-form
      ref="frozenform"
      :model="frozenform"
      :rules="formrules"
      label-width="120px"
    >
      <el-form-item :label="$t('yh.czsl')" prop="num">
        <el-input-number
          v-model="frozenform.num"
          :precision="3"
          :step="0.001"
          :min="0.001"
          :max="total"
        ></el-input-number>
      </el-form-item>
      <el-form-item :label="$t('yh.xzjj')" prop="type">
        <el-radio-group v-model="frozenform.type">
          <el-radio label="2">{{ $t('yh.dongjie') }}</el-radio>
          <el-radio label="3" :disabled="total === 0">{{
            $t('yh.jiedong')
          }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <!-- <el-form-item :label="$t('yh.bibi') + '/' + $t('yh.fabi')" prop="account">
        <el-select v-model="frozenform.account" :placeholder="$t('qxz')">
          <el-option :label="$t('yh.bibi')" value="usable"></el-option>
          <el-option :label="$t('yh.fabi')" value="legal_tender"></el-option>
          <el-option label="defi" value="defi"></el-option>
          <el-option label="外汇" value="contract"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('yh.cplx')" prop="productid">
        <el-select v-model="frozenform.productid" :placeholder="$t('qxz')">
          <el-option
            v-for="item in product"
            :key="item.id"
            :label="item.product_name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item> -->
      <el-form-item :label="$t('yh.djjdyy')" prop="reason">
        <el-input v-model="frozenform.reason"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click.native="submit">{{
        $t('tijiao')
      }}</el-button>
      <el-button @click="handleClose">{{ $t('quxiao') }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      total: 0,
      product: [],
      // 拥有的资产对象
      hasassets: {
        contract: 0,
        legal_tender: 0,
        defi: 0
      },
      // 冻结解冻资产数据对象
      frozenform: {
        uid: '',
        num: '0.001',
        type: '2',
        account: 'contract',
        productid: '1',
        reason: ''
      },
      // 表单验证对象
      formrules: {
        num: [
          { required: true, message: '必填项0.001-99.999', trigger: 'blur' }
        ],
        type: [{ required: true, message: '请选择操作类型', trigger: 'blur' }],
        account: [{ required: true, message: '请选择币种', trigger: 'blur' }],
        productid: [
          { required: true, message: '请选择产品类型', trigger: 'blur' }
        ],
        reason: [{ required: true, message: '请填写原因', trigger: 'blur' }]
      }
    }
  },
  props: ['visible', 'uid'],
  methods: {
    handleClose() {
      this.$emit('close')
    },
    // 打开弹窗请求数据
    async open() {
      const loading = this.$loading({
        lock: true,
        spinner: 'el-icon-loading',
        background: 'rgba(255,255, 255, 0.7)',
        target: document.querySelector('.el-dialog__body')
      })
      // 请求用户资产数据
      const { data } = await this.$http.get(
        '/admin/user/assets/uid/' + this.uid
      )
      if (data) {
        if (data.code === 200) {
          this.total = data.data.info.total
          this.hasassets.contract = data.data.info.contract
          this.hasassets.contract_freeze = data.data.info.contract_freeze
          this.hasassets.legal_tender = data.data.info.legal_tender
          this.hasassets.legal_tender_freeze = data.data.info.legal_tender_freeze
          this.hasassets.defi = data.data.info.defi
          this.hasassets.usable = data.data.info.usable
          this.hasassets.freeze = data.data.info.freeze
          this.hasassets.defi = data.data.info.defi
          this.hasassets.defi_freeze = data.data.info.defi_freeze
          this.frozenform.uid = data.data.info.user_id
          this.product = data.data.product
        } else {
          this.$message.error(this.$t('shibai'))
        }
      }
      loading.close()
    },
    // 关闭asset弹窗
    closeassetsdialog() {
      this.$refs.frozenform.resetFields()
      this.assetsdialogvisible = false
    },
    submit() {
      this.$refs.frozenform.validate(async valid => {
        if (!valid) return false
        const { data } = await this.$http.post(
          '/admin/user/assets',
          this.frozenform
        )
        if (data) {
          if (data.code === 200) {
            this.total = +this.total + this.frozenform.num + 0
            this.$message.success(this.$t('chenggong'))
            this.handleClose()
          } else {
            this.$message.error(this.getlang(data.msg))
          }
        }
      })
    }
  }
}
</script>
